import WordRepository from "@/API/WordRepository";
import { StoreMutations } from "./mutations";
import { StoreState } from "./state";
import QuestionRepository from "@/API/QuestionRepository";
import Question from "@/adapters/Question";
import Word from "@/adapters/Word";
import AnswerRepository from "@/API/AnswerRepository";
import Answer from "@/adapters/Answer";
import EventRepository from "@/API/EventRepository";

export const StoreActions = {
    getQuestions: 'getQuestions',
    getWord: 'getWord',
    saveAnswer: 'saveAnswer',
    clearState: 'clearState',
    complete: 'complete',
    logEvent: 'logEvent'
};

export const actions = {
    [StoreActions.getQuestions] ({ commit, state }) {
        let id = state[StoreState.wordId];
        commit(StoreMutations.SET_LOADING, true);

        QuestionRepository.get(id).then(data => {
            let questions = data.questions.map(it => {
                return new Question(it);
            });
            commit(StoreMutations.SET_QUESTIONS, questions);
        }).catch(error => {
            commit(StoreMutations.SET_ERROR, error);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.getWord] ({ commit, state }) {
        let id = state[StoreState.wordId];
        commit(StoreMutations.SET_LOADING, true);

        WordRepository.get(id).then(data => {
            let word = new Word(data.word);
            commit(StoreMutations.SET_WORD, word);
        }).catch(error => {
            commit(StoreMutations.SET_ERROR, error);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.saveAnswer] ({ dispatch, state }, data) {
        if(data.answers.length > 0) {
            data.answers.forEach(it => {
                dispatch(StoreActions.saveAnswer, { answer: it, answers: [], index: data.index });
            });
            return;
        }

        let questionId = state[StoreState.questions][data.index].id;

        let answer = new Answer({
            answer: data.answer,
            questionId: questionId
        });

        AnswerRepository.save(answer).then(() => {
        }).catch(() => {
            // commit(StoreMutations.SET_ERROR, error);
        });
    },
    [StoreActions.complete] (context) {
        WordRepository.complete(context.state[StoreState.wordId]);
    },
    [StoreActions.logEvent] (context, data) {
        EventRepository.create(data);
    },
    [StoreActions.clearState] ({ commit }) {
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_QUESTIONS, []);
        commit(StoreMutations.SET_WORD_ID, null);
        commit(StoreMutations.SET_WORD, null);
        commit(StoreMutations.SET_ERROR, null);
    }
}