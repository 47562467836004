import APIConnection from '@/API/APIConnection';
export default {
    register(data){
        return APIConnection.post('user/create', data);
    },
    login(data){
        return APIConnection.post('user/login', data);
    },
    get() {
        return APIConnection.get('user');
    },
    update(data) {
        return APIConnection.patch('user', data);
    },
    studentLogin(data) {
        return APIConnection.post('user/login/student', data);
    },
    updateSessionProfile(profileId) {
        return APIConnection.patch(`user/session/profile/${profileId}`, {});
    },
    clearSessionProfile() {
        return APIConnection.patch('user/session/clear/profile');
    },
    getSessionDetails() {
        return APIConnection.get('user/session/details');
    },
    updateEmail(data) {
        return APIConnection.put('user/email', data);
    },
    resendVerifyEmail() {
        return APIConnection.post('user/email/resend', {});
    },
    verifyEmail(data) {
        return APIConnection.post('user/email/verify', data);
    },
    forgotPassword(data) {
        return APIConnection.post('user/password/forgot', data);
    },
    resetPassword(data) {
        return APIConnection.post('user/password/reset', data);
    },
    passport() {
        return APIConnection.get('passport');
    },
    export() {
        return APIConnection.post('user/export');
    },
    delete() {
        return APIConnection.delete('/user');
    },
    shareLink(data) {
        return APIConnection.post('/link/email', data);
    }
}