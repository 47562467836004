<template>
  <div>
    <main-nav />
    <div class="container-full primary">
      <div class="row mt20">
        <div class="col-sm-12">
          <h2>{{completedSetup ? 'Edit' : 'Complete'}} Profile</h2>
        </div>
        <div
          class="col-xs-12 col-sm-8 col-md-6 col-sm-offset-2 col-md-offset-3"
        >
          <i class="fa fa-spin fa-spinner fa-lg mb20" v-if="loading" />
          <br /><br />

          <div class="row mt20" v-if="error">
            <div class="col-sm-12">
              <div class="alert" role="alert">
                {{ error }}
              </div>
            </div>
          </div>

          <div class="form-group" v-if="!loading">
            <label>Type *</label>
            <select class="form-control" v-model="type">
              <option value="">Select One</option>
              <option value="Educator">Educator</option>
              <option value="Parent">Parent</option>
            </select>
          </div>

          <div v-if="type == 'Educator'">
            <div class="form-group">
              <label>School District *</label>

              <h4
                class="primary curved"
                style="padding: 10px"
                v-if="selectedSchool"
              >
                {{ selectedSchool.name }}
              </h4>
              <h4
                class="primary curved"
                style="padding: 10px"
                v-if="selectedClassroom"
              >
                {{ selectedClassroom.name }}
              </h4>

              <div v-if="false">
                <select-school-classroom
                  style="margin: 10px"
                  :schools="schools"
                  @selectedSchool="didSelectSchool"
                  @selectedClassroom="didSelectClassroom"
                />
                <hr style="color: white" />
                <p>Don't see your school? Enter it below:</p>
              </div>

              <div>
                <input
                  class="form-control"
                  v-model="schoolDistrict"
                  placeholder="..."
                  :disabled="loading"
                />
              </div>
            </div>

            <div class="form-group">
              <label>School *</label>
              <input
                class="form-control"
                v-model="school"
                placeholder="..."
                :disabled="loading"
              />
            </div>

            <div class="form-group">
              <label>Grade *</label>
              <select class="form-control" v-model="grade">
                <option value="">Select One</option>
                <option :value="grade" v-for="grade in grades" :key="grade">
                  {{ grade }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label>Classroom Code *</label><br />
              <small
                >Your students can use this to log into their profile</small
              >
              <input
                class="form-control"
                v-model="accessCode"
                placeholder="..."
                :disabled="loading"
              />
            </div>
          </div>

          <div class="row">
            <h4>Email Settings</h4>

            <div class="form-group">
              <label>Word Completion</label>
              <small
                ><br />Receive an email when one of the student profiles
                completes a word</small
              >
              <select class="form-control" v-model="completedWordEmail">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <div class="form-group">
              <label>All Words Completed</label>
              <small
                ><br />Receive an email when one of the student profiles
                completes all of the words of CAN</small
              >
              <select class="form-control" v-model="completedAllWordsEmail">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>

          <button
            :disabled="loading"
            @click="saveClick"
            class="btn btn-primary btn-login"
          >
            Save
          </button>

          <br /><br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/completeProfile/state";
import { StoreActions as actions } from "@/store/completeProfile/actions";
import { StoreMutations as mutations } from "@/store/completeProfile/mutations";
import { StoreActions as GlobalActions } from "@/store/global/actions";
import SelectSchoolClassroom from "@/components/user/SelectSchoolClassroom.vue";
import MainNav from "@/components/MainNav.vue";
import routes from "@/routes";

export default {
  components: {
    SelectSchoolClassroom,
    MainNav,
  },
  name: "CompleteProfile",
  mounted() {
    this.dispatch(actions.getGrades);
    this.commit(mutations.SET_LOADING, true);
    this.$store.dispatch(GlobalActions.getUser, (user) => {
      this.commit(mutations.SET_LOADING, false);
      if (user) {
        this.type = user?.type;
        this.school = user?.school;
        this.schoolDistrict = user?.schoolDistrict;
        this.grade = user?.grade;
        this.accessCode = user?.accessCode;
        this.completedWordEmail = `${user.completedWordEmail}`;
        this.completedAllWordsEmail = `${user.completedAllWordsEmail}`;
        this.completedSetup = user.completedSetup;
      }

      if (!this.accessCode) {
        this.accessCode = `${user.firstName
          .toLowerCase()
          .substring(0, 1)}${user.lastName.toLowerCase()}`;
      }
    });

    this.dispatch(actions.getSchools);
  },
  data() {
    return {
      type: "",
      school: "",
      schoolDistrict: "",
      grade: "",
      accessCode: "",
      selectedSchool: null,
      selectedClassroom: null,
      completedWordEmail: false,
      completedAllWordsEmail: false,
      completedSetup: false
    };
  },
  computed: {
    ...mapState(StorePaths.completeProfile, [
      state.loading,
      state.error,
      state.grades,
      state.schools,
    ]),
    homeRoute() {
      return {
        name: routes.home.name,
      };
    },
    profileRoute() {
      return {
        name: routes.myProfile.name,
      };
    },
  },
  methods: {
    saveClick() {
      this.commit(mutations.SET_ERROR, null);
      if (!this.type) {
        this.commit(mutations.SET_ERROR, "Type is required");
      }

      this.dispatch(actions.save, {
        type: this.type,
        school: this.school,
        schoolDistrict: this.schoolDistrict,
        grade: this.grade,
        completedSetup: true,
        accessCode: this.type == "Educator" ? this.accessCode : null,
        schoolId: this.selectedSchool?.id,
        classroomId: this.selectedClassroom?.id,
        completedAllWordsEmail: this.completedAllWordsEmail == "true" ? 1 : 0,
        completedWordEmail: this.completedWordEmail == "true" ? 1 : 0,
      });
    },
    commit(key, val) {
      this.$store.commit(`${StorePaths.completeProfile}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.completeProfile}/${key}`, val);
    },
    didSelectSchool(school) {
      this.selectedSchool = school;
    },
    didSelectClassroom(classroom) {
      this.selectedClassroom = classroom;
    },
  },
};
</script>

<style scoped>
.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}

.btn-primary {
  border-radius: 10px;
}
</style>