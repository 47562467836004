import AdminStatsRepository from '@/API/AdminStatsRepository';
import { StoreMutations } from "./mutations";

export const StoreActions = {
    getStats: 'getStats',
    getPageViews: 'getPageViews',
    clearState: 'clearState',
};

export const actions = {
    [StoreActions.getStats] ({ commit }) {
        AdminStatsRepository.stats().then((data) => {
            commit(StoreMutations.SET_STATS, data.stats);
        }).catch( err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    [StoreActions.getPageViews] ({ commit }) {
        AdminStatsRepository.pageViews().then((data) => {
            commit(StoreMutations.SET_PAGE_VIEWS, data.pageViews);
        }).catch( err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    
    [StoreActions.clearState] ({ commit }) {
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_ERROR, null);
    }
}