import { StoreState } from "./state";

export const StoreMutations = {
    SET_LOADING: 'SET_LOADING',
    SET_STATS: 'SET_STATS',
    SET_PAGE_VIEWS: 'SET_PAGE_VIEWS',
    SET_ERROR: 'SET_ERROR'
};

export const mutations = {
    [StoreMutations.SET_LOADING](state, value) {
        state[StoreState.loading] = value;
    },
    [StoreMutations.SET_STATS](state, value) {
        state[StoreState.stats] = value;
    },
    [StoreMutations.SET_PAGE_VIEWS](state, value) {
        state[StoreState.pageViews] = value;
    },
    [StoreMutations.SET_ERROR](state, value) {
        state[StoreState.error] = value;
    }
}