<template>
  <div>
    <main-nav />
    <div class="container-full primary">
      <div class="row mt20">
        <div class="col-sm-12">
          <h2>Create an Account</h2>
        </div>
        <div
          class="col-xs-12 col-sm-8 col-md-4 col-sm-offset-2 col-md-offset-4"
        >
          <img
            :src="canLogo"
            width="100"
            class="mt20 ml10"
          />
          <br /><br />
          <sync-loader :loading="loading" :color="`#f35b2c`" :size="'15px'" style="margin-top:20px"></sync-loader>

          <div class="row mt20" v-if="error">
            <div class="col-sm-12">
              <div class="alert" role="alert">
                {{ error }}
              </div>
            </div>
          </div>

          <div v-if="destination == 'can'">
            <h4>In order to keep track of your progress, you must have a parent or guardian create a FREE account to access CAN14. <br><br>If you already have an account, select the login button below.</h4>
          </div>

          <div v-if="step == 0">
            <div class="form-group">
              <label>Access Code
                <i v-tooltip="'You should have received this in a book or in a doctor\'s office'"
                class="fa fa-info-circle" />
              </label>
              <input
                class="form-control"
                v-model="accessCode"
                placeholder="123456"
                :disabled="loading"
                v-on:keyup.enter="nextClick"
              />
            </div>

            <button  
              @click="nextClick"
              :disabled="loading"             
              class="btn btn-primary btn-login"
            >Next</button>
          </div>
          <div v-else>

            <div class="form-group">
              <label>First Name</label>
              <input
                class="form-control"
                v-model="firstName"
                placeholder="Steve"
                :disabled="loading"
                v-on:keyup.enter="registerClick"
              />
            </div>

            <div class="form-group">
              <label>Last Initial</label>
              <input
                class="form-control"
                v-model="lastName"
                placeholder="S"
                :disabled="loading"
                v-on:keyup.enter="registerClick"
              />
            </div>

            <div class="form-group">
              <label>Email</label>
              <input
                class="form-control"
                type="email"
                v-model="email"
                placeholder="me@example.com"
                :disabled="loading"
                v-on:keyup.enter="registerClick"
              />
            </div>

            <div class="form-group">
              <label>Create Password</label>
              <input
                class="form-control"
                type="password"
                v-model="password"
                placeholder="..."
                :disabled="loading"
                v-on:keyup.enter="registerClick"
              />
            </div>

            <p>You must be 18 years or older to create an account.</p>

            <button
              :disabled="loading"
              @click="registerClick"
              class="btn btn-primary btn-login"
            >
              Register
            </button>

            <router-link :to="homeRoute">
              <button class="btn btn-default btn-login mt20">Back</button>
            </router-link>

          </div>
          <br><br>
          <p>Already have an account?</p>

          <router-link :to="loginRoute" class="no-decoration">
            <button class="btn btn-default btn-register">Login</button>
          </router-link>

          <br><br>
          <a href="https://api.app.thelandofcan.com/public/privacy.html" target="_blank" style="margin-top: 20px; color: #f35b2c;">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routes from "@/routes";
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/register/state";
import { StoreActions as actions } from "@/store/register/actions";
import { StoreMutations as mutations } from "@/store/register/mutations";
import MainNav from "@/components/MainNav.vue";
import CanLogo from '@/assets/images/canlogo.png';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
export default {
  name: "UserRegister",
  components: {
    MainNav,
    SyncLoader
  },
  mounted() {
    let e = this.$route.query.email || "";
    this.email = e;
    if (this.$hasSession()) {
      this.$router.push({name: routes.home.name });
    }

    this.commit(mutations.SET_DESTINATION, this.$route.query.destination);
    this.accessCode = this.$getAccessCode();
    if(this.accessCode == 'null') {
      this.accessCode = ''
    } else {
      this.step = 1
    }
  },
  beforeUnmount() {
    this.dispatch(actions.clearState);
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      accessCode: "",
      canLogo: CanLogo,
      step: 0,
      registerDoctorId: null,
      registerClassroomId: null,
      homepageId: null
    };
  },
  computed: {
    ...mapState(StorePaths.register, [state.loading, state.error, state.destination]),
    loginRoute() {
      return {
        name: routes.login.name,
        query: {
          email: this.email,
          destination: this.destination
        },
      };
    },
    homeRoute() {
      if(this.destination == "can") {
        return {
          name: routes.home.name
        }
      } else {
        return {
          name: routes.login.name
        }
      }
    },
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.register}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.register}/${key}`, val);
    },
    registerClick() {
      this.commit(mutations.SET_ERROR, null);
      this.dispatch(actions.register, {
        accessCode: this.accessCode,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        registerDoctorId: this.registerDoctorId,
        registerClassroomId: this.registerClassroomId,
        homepageId: this.homepageId
      });
    },
    nextClick() {
      var success = (code) => {
        this.step = 1;
        this.registerDoctorId = code.doctorId;
        this.registerClassroomId = code.classroomId;
        this.homepageId = code.homepageId;
      };

      this.dispatch(actions.checkCode, {code: this.accessCode, callback: success});
    }
  },
};
</script>

<style scoped>

input {
  border-radius: 10px !important;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}

.btn-login {
  border-radius: 10px;
  font-size: medium;
  width: 100%;
}

.btn-register {
  border-radius: 10px;
  font-size: medium;
  width: 100%;
  margin-top: 20px;
}

.btn-forgot {
  margin-top: 20px;
}

.logo {
  height: 50px;
  margin-top: 10px;
}

.container-full {
  padding: 0px;
}

.student-login {
  color: white;
  font-size: medium;
  font-weight: bold;
}
</style>