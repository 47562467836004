<template>
  <div>
    <main-nav />
    <div class="container-full primary">

      <div class="row">
        <div class="col-sm-6 col-sm-offset-3 mt20">
          <img height="200" src="https://api.app.thelandofcan.com/storage/images/shield.png" />
          <h1>Welcome to Lancaster/Lebanon Can!</h1>
          <br><br>
          <router-link :to="studentLoginRoute">
            <button style="border-radius: 10px; width: 100%; font-size: large;" class="btn btn-primary">Student Login</button>
          </router-link>

          <br><br>

          <router-link :to="teacherLoginRoute">
            <button style="border-radius: 10px; width: 100%; font-size: large;" class="btn btn-default">Educator Login</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StoreActions as GlobalActions } from '@/store/global/actions';
import routes from '@/routes';
import MainNav from '@/components/MainNav.vue';
export default {
  name: "OhioCanLanding",
  components: {
    MainNav
  },
  mounted() {

    this.$store.dispatch(GlobalActions.getSessionDetails, () => {
      this.$router.push(routes.home);
    });

    this.$store.dispatch(GlobalActions.getUser, (user) => {
      if(user) {
        this.$router.push(routes.home);
      }
    });
  },
  data() {
    return {
    };
  },
  computed: {
    studentLoginRoute() {
      return {
        name: routes.studentLogin.name
      }
    },
    teacherLoginRoute() {
      return {
        name: routes.lancasterCanlanding.name
      }
    }
  }
};
</script>

<style scoped>

.disabled {
  cursor: not-allowed;
}

.logout {
  color:white;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.countdown-container {
  background: #efefef;
  border-radius: 10px;
  margin-bottom:20px;
}

.fa-refresh {
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

.btn-main {
  min-width: 100px;
  border-radius: 10px;
  font-size: 14pt;
}

h1 {
  font-size: 40pt;
}

.desc {
  font-size: 14pt;
}

.desc-section {
  font-size: 14pt;
}
</style>