<template>
  <modal
      id="modal-demo"
      ref="modal"
      v-model="open"
      title="Share Link"
      @hide="callback"
      :footer="false"
    >
    <p>Enter your email below to have this link sent to you:</p>
    <input class="form-control" placeholder="abc@example.com" v-model="email" />
    <br>
    <button @click="send" class="btn btn-primary">Send</button>


  </modal>
</template>

<script>
import { StoreMutations } from '@/store/global/mutations';
import { StoreActions as actions } from "@/store/home/actions";
import StorePaths from "@/store/StorePaths";

export default {
    name: "SendLinkModal",
    components: {
    },
    props: {
        showing: {
            default: false
        }
    },
    data(){ 
        return {
            open: false,
            email: ""
        }
    },
    methods: {
        dispatch(key, val) {
        this.$store.dispatch(`${StorePaths.home}/${key}`, val);
        },
        callback() {
            this.$emit("closed")
            this.$store.commit(StoreMutations.SET_SHOW_VERIFY_EMAIL, false);
        },
        send() {
            this.dispatch(actions.shareLink, {
                email: this.email,
                accessCode: this.$getAccessCode()
            })
            this.$emit("closed");
        }
    },
    watch: {
        showing(newVal) {
            this.open = newVal
        }
    }
}
</script>

<style>

</style>