import UserRepository from "@/API/UserRepository";
import { StoreMutations } from "./mutations";
import app from "@/main";
import routes from "@/routes";
import AccessCodeRepository from "@/API/AccessCodeRepository";
import AccessCode from "@/adapters/AccessCode";
export const StoreActions = {
    register: 'register',
    checkCode: 'checkCode',
    clearState: 'clearState'
};

export const actions = {
    [StoreActions.register] ({ commit, state }, data) {
        commit(StoreMutations.SET_LOADING, true);
        commit(StoreMutations.SET_ERROR, null);

        UserRepository.register(data).then( res => {
            let session = res.session;
            app.config.globalProperties.$setSession(session);

            let code = state.code;

            if(state.destination == "can") {
                app.config.globalProperties.$router.push({name: routes.words.name});
            } else if (code.mustCompleteProfile) {
                app.config.globalProperties.$router.push({name: routes.completeProfile.name});
            } else {
                app.config.globalProperties.$router.push({name: routes.home.name});
            }
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.checkCode] ({ commit }, props) {
        commit(StoreMutations.SET_LOADING, true);
        commit(StoreMutations.SET_ERROR, null);

        AccessCodeRepository.check(props.code).then( res => {
            let code = new AccessCode(res.code);
            props.callback(code);
            commit(StoreMutations.SET_CODE, code);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.clearState] ({commit}) {
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_DESTINATION, null);
        commit(StoreMutations.SET_CODE, null);
        commit(StoreMutations.SET_LOADING, false);
    }
}