export const StoreState = {
    loading: 'loading',
    error: 'error',
    lessonPlans: 'lessonPlans'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.error]: null,
    [StoreState.lessonPlans]: []
}