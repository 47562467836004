<template>
  <div>
    <main-nav />
    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <h2>Lesson Plans</h2>
        </div>
        <div class="col-sm-12">
          <sync-loader :loading="loading" :color="`#f35b2c`" :size="'15px'" style="margin-top:20px"></sync-loader>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 mt20" v-for="plan in lessonPlans" :key="plan.id">
          <a :href="plan.link" target="_blank">
            <div class="book">
              <img class="pt10" :src="plan.image" />
              <h4 class="pb10 black">{{plan.name}}</h4>
                <button class="btn btn-primary mb10">Read Lesson Plan</button>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/lessonPlans/state";
import { StoreActions as actions } from "@/store/lessonPlans/actions";
import MainNav from './MainNav.vue';
export default {
  name: "LessonPlans",
  components: {
    SyncLoader,
    MainNav
  },
  mounted() {
    this.loading = true;
    this.dispatch(actions.getLessonPlans);

  },
  computed: {
    ...mapState(StorePaths.lessonPlans, [state.loading, state.error, state.lessonPlans])
  },
  data() {
    return {
    };
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.lessonPlans}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.lessonPlans}/${key}`, val);
    }
  }
};
</script>

<style scoped>

.disabled {
  cursor: not-allowed;
}

.logout {
  color:white;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.countdown-container {
  background: #efefef;
  border-radius: 10px;
  margin-bottom:20px;
}

.fa-refresh {
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

.btn-main {
  min-width: 100px;
  border-radius: 10px;
  font-size: 14pt;
}

h1 {
  font-size: 40pt;
}

.desc {
  font-size: 14pt;
}

.desc-section {
  font-size: 14pt;
}

.book {
  background: #efefef;
  border-radius: 10px;
  margin: 10px;
}

.btn-primary {
  border-radius: 10px;
}

.book > img {
  height: 200px;
  width: auto;
}
</style>