export default {
    words: 'words',
    question: 'question',
    register: 'register',
    login: 'login',
    completeProfile: 'completeProfile',
    myProfile: 'myProfile',
    preRegister: 'preRegister',
    adminSchools: 'adminSchools',
    adminAccessCodes: 'adminAccessCodes',
    adminDoctors: 'adminDoctors',
    adminWords: 'adminWords',
    adminQuestions: 'adminQuestions',
    adminQuestionEdit: 'adminQuestionEdit',
    adminHomepages: 'adminHomepages',
    home: 'home',
    healthbooks: 'healthbooks',
    adminHome: 'adminHome',
    foodAllergyResources: 'foodAllergyResources',
    canboard: 'canboard',
    lessonPlans: 'lessonPlans',
    adminUsers: 'adminUsers'
};