<template>
  <div>
    <main-nav />

    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <sync-loader
            :loading="loading"
            :color="`#f35b2c`"
            :size="'15px'"
            style="margin-top: 20px"
          ></sync-loader>
        </div>
        <div class="col-sm-8 col-sm-offset-2 mt20" v-if="home">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-lg-offset-3">
              <h1>{{ home.title }}</h1>
              <p class="desc">{{ home.desc }}</p>
            </div>
          </div>
          <div class="row mt20">
            <div class="col-sm-12 col-md-4 col-md-offset-4">
              <p>Enter your access code to get started:</p>
              <div class="form-group">
                <label>Access Code</label>
                <input
                  @keyup.enter="submitCode"
                  class="form-control" v-model="accessCode" 
                />

                <div class="row mt20" v-if="error">
                  <div class="col-sm-12">
                    <div class="alert" role="alert">
                      {{ error }}
                    </div>
                  </div>
                </div>

                <button  @click="submitCode" class="btn btn-primary mt20">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import routes from "@/routes";
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/home/state";
import { StoreActions as actions } from "@/store/home/actions";
import MainNav from './MainNav.vue';

export default {
  name: "LandingLogin",
  components: {
    SyncLoader,
    MainNav,
  },
  mounted() {
    if(this.$hasSlug()) {
      this.$router.push({name: routes.home.name});
      return;
    }

    let slug = this.$route.params.slug;
    this.dispatch(actions.homeBySlug, slug);

    let accessCode = this.$route.query.accessCode
    if(accessCode) {
      this.accessCode = accessCode
      this.submitCode()
    }
  },
  computed: {
    ...mapState(StorePaths.home, [state.loading, state.error, state.home]),
  },
  data() {
    return {
      loading: false,
      accessCode: null,
    };
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.home}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.home}/${key}`, val);
    },
    submitCode() {
      let props = {
        code: this.accessCode,
        callback: (obj) => { 
          if(obj.homepageId) {
            this.$setAccessCode(obj.accessCode);
            this.$setSlug(obj.homepageSlug);
            this.$router.push({name: routes.home.name});
          } else {
            this.$router.push({name: routes.register.name});
          }
        }
      };
      this.dispatch(actions.checkCode, props);
    }
  },
};
</script>

<style scoped>
.disabled {
  cursor: not-allowed;
}

.logout {
  color: white;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.countdown-container {
  background: #efefef;
  border-radius: 10px;
  margin-bottom: 20px;
}

.fa-refresh {
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

.btn-main {
  min-width: 100px;
  border-radius: 10px;
  font-size: 14pt;
}

h1 {
  font-size: 40pt;
}

.desc {
  font-size: 14pt;
}

.desc-section {
  font-size: 14pt;
}
</style>