<template>
  <div>
    <main-nav :color="'black'" />
    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <h2>Admin</h2>
        </div>
      </div>
      <sync-loader
        :loading="loading"
        :color="`#f35b2c`"
        :size="'15px'"
        style="margin-top: 20px"
      ></sync-loader>

      <div class="row mt20" v-if="error">
        <div class="col-sm-12">
          <div class="alert" role="alert">
            {{ error }}
          </div>
        </div>
      </div>

      <div class="row mt20">
        <admin-nav :active="activeRoute" />
        <div v-if="stats">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
            <div class="secondary ml30 curved mr20">
              <h4>Users</h4>
              <h4>
                <strong>{{ stats.users }}</strong>
              </h4>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
            <div class="secondary ml30 curved mr20">
              <h4>Student Profiles</h4>
              <h4>
                <strong>{{ stats.profiles }}</strong>
              </h4>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
            <div class="secondary ml30 curved mr20">
              <h4>Completed Words</h4>
              <h4>
                <strong>{{ stats.completedWords }}</strong>
              </h4>
            </div>
          </div>

          <div class="col-sm-9">
            <div class="ml20 mr20">
              <h3>Page Views</h3>

              <div class="form-group">
                <label>Date</label>
                <select class="form-control" v-model="pageViewDate">
                  <option
                    v-for="date in pageViewDates"
                    :key="date"
                    :value="date"
                  >
                    {{ formattedDate(date) }}
                  </option>
                </select>
              </div>

              <div v-if="pageViewDate != ''">
                <p>{{totalPageViews}} Page Views</p>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Page</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="page in dayPageViews" :key="page.link">
                      <td style="text-align:left">{{page.link}}</td>
                      <td style="text-align:left">{{page.count}}</td>
                    </tr>
                  </tbody>
                </table>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/admin/home/state";
import { StoreActions as actions } from "@/store/admin/home/actions";
import { StoreActions as GlobalActions } from "@/store/global/actions";
import MainNav from "@/components/MainNav.vue";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import routes from "@/routes";
import AdminNav from "./AdminNav.vue";
import moment from 'moment';
export default {
  name: "AdminHome",
  components: {
    MainNav,
    SyncLoader,
    AdminNav,
  },
  mounted() {
    this.$store.dispatch(GlobalActions.getUser, (user) => {
      if (!user || !user.admin) {
        this.$logout();
      } else {
        this.dispatch(actions.getStats);
        this.dispatch(actions.getPageViews);
      }
    });
  },
  data() {
    return {
      adminLinks: routes.adminLinks(),
      pageViewDate: "",
    };
  },
  computed: {
    ...mapState(StorePaths.adminHome, [
      state.loading,
      state.stats,
      state.pageViews,
    ]),
    activeRoute() {
      return routes.admin.home.name;
    },
    pageViewDates() {
      let dates = this.pageViews.map((it) => {
        return it.date;
      });
      return [...new Set(dates)];
    },
    dayPageViews() {
      return this.pageViews.filter(it => {
        return it.date == this.pageViewDate
      })
    },
    totalPageViews() {
      let views = this.dayPageViews;
      var total = 0;
      views.forEach(it => {
        total += it.count;
      })
      return total;
    }
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.adminHome}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.adminHome}/${key}`, val);
    },
    logout() {
      this.$logout();
    },
    formattedDate(date) {
      return moment(date).format("MMM DD YY")
    }
  },
  watch: {
    pageViews(newVal) {
      if(newVal.length > 0) {
        this.pageViewDate = newVal[0].date;
      }
    }
  }
};
</script>

<style scoped>
.secondary {
  padding: 10px;
}

p {
  margin: 0px;
  font-weight: bold;
}

label {
  color: gray;
}

.curved {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  cursor: pointer;
  height: 85px;
  line-height: 85px;
  font-weight: bold;
  font-size: large;
  overflow: scroll;
}

.add-card {
  cursor: pointer;
  height: 85px;
  font-weight: bold;
  font-size: large;
  padding: 10px;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

.bulk-btn {
  border-radius: 10px;
}

nav {
  margin-bottom: 0px;
}
</style>