import HomeSection from "./HomeSection";

export default class Home {
    constructor(adapter = {
        id: 0,
        title: "",
        title2: "",
        title3: "",
        desc: "",
        image: null,
        buttonTitle: null,
        buttonLink: null,
        sections: [],
        slug: null,
        video: null
    }) {
        this.id = adapter.id;
        this.title = adapter.title;
        this.title2 = adapter.title2;
        this.title3 = adapter.title3;
        this.desc = adapter.desc;
        this.image = adapter.image;
        this.buttonTitle = adapter.buttonTitle;
        this.buttonLink = adapter.buttonLink;
        this.sections = adapter.sections?.map(it => {
            return new HomeSection(it);
        });
        this.slug = adapter.slug;
        this.video = adapter.video;
    }
}