import Doctor from "./Doctor";
import Classroom from "./Classroom";
import School from "./School";
import Home from "./Home";

export default class {
    constructor(adapter = {
        id: 0,
        accessCode: "",
        allowedUses: 0,
        numUses: 0,
        remainingUses: 0,
        doctorId: null,
        classroomId: null,
        homepageId: null,
        doctor: null,
        classroom: null,
        homepageSlug: null,
        homepage: null,
        mustCompleteProfile: false
    }) {
        this.id = adapter.id;
        this.accessCode = adapter.accessCode;
        this.allowedUses = adapter.allowedUses;
        this.numUses = adapter.numUses;
        this.remainingUses = adapter.remainingUses;
        this.doctorId = adapter.doctorId;
        this.classroomId = adapter.classroomId;
        this.homepageId = adapter.homepageId;
        this.homepageSlug = adapter.homepageSlug;
        this.mustCompleteProfile = adapter.mustCompleteProfile;

        this.doctor = null;
        if(adapter.doctor) {
            this.doctor = new Doctor(adapter.doctor);
        }

        this.classroom = null;
        this.school = null;
        if(adapter.classroom) {
            this.classroom = new Classroom(adapter.classroom);
            this.school = new School(adapter.classroom.school);
        }

        if(adapter.homepage) {
            this.homepage = new Home(adapter.homepage);
        }
    }
}