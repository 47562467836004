<template>
  <div>
    <main-nav />
    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <sync-loader :loading="loading" :color="`#f35b2c`" :size="'15px'" style="margin-top:20px"></sync-loader>
        </div>
        <div class="col-sm-8 col-sm-offset-2 mt20">
          <img src="https://cdn.shopify.com/s/files/1/0487/1641/5125/files/shield_new_150x.png?v=1601140238" />

          <!-- <iframe src="https://player.vimeo.com/video/778143454?h=c010fe2f09" width="400" height="400" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> -->


          <h1>Your Pediatricians care!</h1>
          <h4>Your pediatricians care, which is why they have purchased these CAN resources for your family! Enter the access code below to unlock your FREE health, empowerment, and safety resources! Enjoy!</h4>
          <br><br>
          <onboarding />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StoreActions as GlobalActions } from '@/store/global/actions';
import { StoreActions as actions } from "@/store/home/actions";
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import routes from '@/routes';
import MainNav from '@/components/MainNav.vue';
import StorePaths from "@/store/StorePaths";
import Onboarding from '@/components/Onboarding.vue';
export default {
  name: "PediatricianCanLanding",
  components: {
    SyncLoader,
    MainNav,
    Onboarding
  },
  mounted() {

    this.$store.dispatch(GlobalActions.getSessionDetails, () => {
      this.$router.push(routes.home);
    });

    this.loading = true;
    this.$store.dispatch(GlobalActions.getUser, (user) => {
      this.loading = false;
      if(user) {
        this.$router.push(routes.home);
      }
    });

    let accessCode = this.$route.query.accessCode
    if(accessCode) {
      this.accessCode = accessCode
      this.submitCode()
    }

    if(this.$getAccessCode()) {
      this.accessCode = this.$getAccessCode()
      this.submitCode()
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.home}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.home}/${key}`, val);
    },
    submitCode() {
      let props = {
        code: this.accessCode,
        callback: (obj) => { 
          if(obj.homepageId) {
            this.$setAccessCode(obj.accessCode);
            this.$setSlug(obj.homepageSlug);
            this.$router.push({name: routes.home.name});
          } else {
            this.$router.push({name: routes.register.name});
          }
        }
      };
      this.dispatch(actions.checkCode, props);
    }
  }
};
</script>

<style scoped>

.disabled {
  cursor: not-allowed;
}

.logout {
  color:white;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.countdown-container {
  background: #efefef;
  border-radius: 10px;
  margin-bottom:20px;
}

.fa-refresh {
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

.btn-main {
  min-width: 100px;
  border-radius: 10px;
  font-size: 14pt;
}

h1 {
  font-size: 40pt;
}

.desc {
  font-size: 14pt;
}

.desc-section {
  font-size: 14pt;
}
</style>