import UserRepository from "@/API/UserRepository";
import { StoreMutations } from "./mutations";
import app from '@/main';
import routes from "@/routes";
import ProfileRepository from "@/API/ProfileRepository";
import Profile from "@/adapters/Profile";
import SchoolRepository from "@/API/SchoolRepository";
import User from "@/adapters/User";

export const StoreActions = {
    login: 'login',
    studentLogin: 'studentLogin',
    getProfiles: 'getProfiles',
    getSchools: 'getSchools',
    getProfilesBySchoolClassroom: 'getProfilesBySchoolClassroom',
    forgotPassword: 'forgotPassword',
    resetPassword: 'resetPassword',
    clearState: 'clearState'
};

export const actions = {
    [StoreActions.login] ({ commit, state }, data) {
        commit(StoreMutations.SET_LOADING, true);

        UserRepository.login(data).then( res => {
            let user = new User(res.user);
            let session = res.session;
            app.config.globalProperties.$setSession(session);
            
            if(state.destination == "can") {
                app.config.globalProperties.$router.push({name: routes.words.name});
            } else if(user.type == "Educator" || user.profiles.length == 0) {
                app.config.globalProperties.$router.push({name: routes.home.name});
            } else {
                app.config.globalProperties.$router.push({name: routes.selectProfile.name});
            }
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.studentLogin] ({ commit }, data) {
        commit(StoreMutations.SET_LOADING, true);

        UserRepository.studentLogin(data).then( res => {
            let session = res.session;
            app.config.globalProperties.$setSession(session);
            app.config.globalProperties.$router.push({name: routes.home.name});
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.forgotPassword] ({ commit }, data) {
        commit(StoreMutations.SET_LOADING, true);

        UserRepository.forgotPassword(data).then( () => {
            app.config.globalProperties.$notify({
                type: 'success',
                title: 'Password Reset Email Sent!'
            })
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.resetPassword] ({ commit }, data) {
        commit(StoreMutations.SET_LOADING, true);

        UserRepository.resetPassword(data).then( (res) => {
            let session = res.session;
            app.config.globalProperties.$setSession(session);
            app.config.globalProperties.$router.push({name: routes.home.name});
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.getProfiles] ({ commit }, username) {
        commit(StoreMutations.SET_LOADING, true);

        ProfileRepository.getProfiles(username).then( res => {
            let profiles = res.profiles.map(it => {
                return new Profile(it);
            });

            if(res.owner) {
                let owner =  new User(res.owner);
                commit(StoreMutations.SET_PROFILE_OWNER, owner);
            }

            commit(StoreMutations.SET_PROFILES, profiles);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.getSchools] ({commit}) {
        commit(StoreMutations.SET_LOADING, false);
        SchoolRepository.get().then((data) => {
            commit(StoreMutations.SET_SCHOOLS, data.schools);
        }).catch((e) => {
            commit(StoreMutations.SET_ERROR, e);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.getProfilesBySchoolClassroom] ({commit}, req) {
        commit(StoreMutations.SET_LOADING, true);

        ProfileRepository.getBySchoolClassroom(
            req.schoolId, req.classroomId
        ).then(data => {
            let profiles = data.profiles.map(it => {
                return new Profile(it);
            });
            commit(StoreMutations.SET_PROFILES, profiles);
        })
        .catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.clearState] ({commit}) {
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_DESTINATION, null);
        commit(StoreMutations.SET_PROFILE_OWNER, null);
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_PROFILES, []);
    }
}