<template>
  <div>
    <main-nav />

    <div class="container-full primary">
      <div class="row">
        <div class="col-sm-12">
          <sync-loader :loading="loading" :color="`#f35b2c`" :size="'15px'" style="margin-top:20px"></sync-loader>
        </div>
        <div class="col-sm-8 col-sm-offset-2 mt20">
          <img src="https://cdn.shopify.com/s/files/1/0487/1641/5125/files/shield_new_150x.png?v=1601140238" />
          <h1>Educators, Welcome to Lancaster/Lebanon Can!</h1>
          <h4>As part of Lancaster-Lebanon CAN, your school is offering FREE health, empowerment and safety resources for its staff, students and families. Enjoy!</h4>

          <br><br>
          <p>Use your access code to create your educator account:</p>
          <onboarding :requiresAccount="true" :lancaster="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { StoreActions as GlobalActions } from '@/store/global/actions';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import routes from '@/routes';
import StorePaths from "@/store/StorePaths";
import Onboarding from '@/components/Onboarding.vue';
import MainNav from './MainNav.vue';

export default {
  name: "LancasterCanLanding",
  components: {
    SyncLoader,
    Onboarding,
    MainNav
  },
  mounted() {

    this.$store.dispatch(GlobalActions.getSessionDetails, () => {
      this.$router.push(routes.home);
    });

    this.loading = true;
    this.$store.dispatch(GlobalActions.getUser, (user) => {
      this.loading = false;
      if(user) {
        this.$router.push(routes.home);
      }
    });
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.home}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.home}/${key}`, val);
    }
  }
};
</script>

<style scoped>

.disabled {
  cursor: not-allowed;
}

.logout {
  color:white;
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
}

.countdown-container {
  background: #efefef;
  border-radius: 10px;
  margin-bottom:20px;
}

.fa-refresh {
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

.btn-main {
  min-width: 100px;
  border-radius: 10px;
  font-size: 14pt;
}

h1 {
  font-size: 40pt;
}

.desc {
  font-size: 14pt;
}

.desc-section {
  font-size: 14pt;
}
</style>