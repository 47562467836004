import { StoreMutations } from "./mutations";
import HomeRepository from "@/API/HomeRepository";
import Home from "@/adapters/Home";
import AccessCodeRepository from "@/API/AccessCodeRepository";
import AccessCode from "@/adapters/AccessCode";
import app from '@/main';
import routes from "@/routes";
import UserRepository from "@/API/UserRepository";
import Passport from "@/adapters/Passport";

export const StoreActions = {
    home: 'home',
    homeBySlug: 'homeBySlug',
    checkCode: 'checkCode',
    getPassport: 'getPassport',
    clearState: 'clearState',
    shareLink: 'shareLink'
};

export const actions = {
    [StoreActions.home] ({ commit }, data) {
        commit(StoreMutations.SET_LOADING, true);

        HomeRepository.get(data).then( res => {
            let home = new Home(res.home);
            commit(StoreMutations.SET_HOME, home);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.homeBySlug] ({ commit }, slug) {
        commit(StoreMutations.SET_LOADING, true);

        HomeRepository.getBySlug(slug).then( res => {
            if(!res.home) {
                app.config.globalProperties.$router.push({name: routes.notFound.name});
                return;
            }
            let home = new Home(res.home);
            commit(StoreMutations.SET_HOME, home);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.checkCode] ({ commit }, props) {
        commit(StoreMutations.SET_LOADING, true);
        commit(StoreMutations.SET_ERROR, null);

        AccessCodeRepository.check(props.code).then( res => {
            props.callback(new AccessCode(res.code));
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.getPassport] ({ commit }) {
        commit(StoreMutations.SET_LOADING, true);
        commit(StoreMutations.SET_ERROR, null);

        UserRepository.passport().then( res => {
            let passport = new Passport(res);
            commit(StoreMutations.SET_PASSPORT, passport);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.shareLink] (_, data) {
        UserRepository.shareLink(data).then( () => {
        }).catch(() => {
        }).finally(() => {
        });
    },
    [StoreActions.clearState] ({commit}) {
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_HOME, null);
        commit(StoreMutations.SET_PASSPORT, null);
    }
}