import Vuex from 'vuex';
import WordsStore from '@/store/words/store';
import QuestionStore from '@/store/question/store';
import RegisterStore from '@/store/register/store';
import LoginStore from '@/store/login/store';
import CompleteProfileStore from '@/store/completeProfile/store';
import MyProfileStore from '@/store/myProfile/store';
import PreRegisterStore from '@/store/preRegister/store';
import AdminSchoolsStore from '@/store/admin/schools/store';
import AdminAccessCodesStore from '@/store/admin/accessCodes/store';
import AdminDoctorsStore from '@/store/admin/doctors/store';
import AdminWordStore from '@/store/admin/words/store';
import AdminQuestionStore from '@/store/admin/questions/store';
import AdminQuestionEditStore from '@/store/admin/editQuestion/store';
import AdminHomepageStore from '@/store/admin/homepages/store';
import HomeStore from '@/store/home/store';
import HealthbooksStore from '@/store/healthbooks/store';
import AdminHomeStore from '@/store/admin/home/store';
import FoodAllergyResourceStore from '@/store/foodAllergyResources/store';
import CanboardStore from '@/store/canboard/store';
import LessonPlans from '@/store/lessonPlans/store';
import AdminUsers from '@/store/admin/users/store';

import { state as GlobalState } from "@/store/global/state";
import { actions as GlobalActions } from "@/store/global/actions";
import { mutations as GlobalMutations } from "@/store/global/mutations";
import { getters as GlobalGetters } from "@/store/global/getters";

const store = Vuex.createStore({
modules: {
    words : WordsStore,
    question: QuestionStore,
    register: RegisterStore,
    login: LoginStore,
    completeProfile: CompleteProfileStore,
    myProfile: MyProfileStore,
    preRegister: PreRegisterStore,
    adminSchools: AdminSchoolsStore,
    adminAccessCodes: AdminAccessCodesStore,
    adminDoctors: AdminDoctorsStore,
    adminWords: AdminWordStore,
    adminQuestions: AdminQuestionStore,
    adminQuestionEdit: AdminQuestionEditStore,
    adminHomepages: AdminHomepageStore,
    home: HomeStore,
    healthbooks: HealthbooksStore,
    adminHome: AdminHomeStore,
    foodAllergyResources: FoodAllergyResourceStore,
    canboard: CanboardStore,
    lessonPlans: LessonPlans,
    adminUsers: AdminUsers
},
actions: GlobalActions,
mutations: GlobalMutations,
state: GlobalState,
getters: GlobalGetters
});

export default store;