export const StoreState = {
    loading: 'loading',
    error: 'error',
    destination: 'destination',
    code: 'code'
};

export const state = {
    [StoreState.loading]: false,
    [StoreState.error]: null,
    [StoreState.destination]: null,
    [StoreState.code]: null,
}