<template>
  <modal
      id="modal-demo"
      ref="modal"
      v-model="open"
      title="Complete Profile"
      @hide="callback"
      :footer="false"
    >
    <div>
        <p>Do you want to finish setting up your profile? <br>This will only take a minute.</p>
        <p v-if="user && user.type == 'Educator'"><strong>Your students can't login until you complete this</strong></p>
        <button @click="callback" class="btn btn-default">Dismiss</button>
        <router-link :to="completeProfileLink">
            <button class="btn btn-primary ml10">Complete Profile</button>
        </router-link>
    </div>
  </modal>
</template>

<script>
import routes from '@/routes'
import { StoreActions as GlobalActions } from '@/store/global/actions';

export default {
    components: {
    },
    mounted() {
        this.$store.dispatch(GlobalActions.getUser, (user) => {
            this.loading = false;
            if(user) {

                this.user = user;
            }
        });  
    },
    props: {
        showing: {
            default: false
        }
    },
    data(){ 
        return {
            open: false,
            user: null
        }
    },
    computed: {
        completeProfileLink() {
            return {name: routes.completeProfile.name};
        }
    },
    methods: {
        callback() {
            this.$emit("closed")
        }
    },
    watch: {
        showing(newVal) {
            this.open = newVal
        }
    }
}
</script>

<style scoped>
.btn {
    margin-top: 10px;
    border-radius: 15px;
    font-size: medium;
}
</style>