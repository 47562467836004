import LessonPlan from "@/adapters/LessonPlan";
import LessonPlanRepository from "@/API/LessonPlanRepository";
import { StoreMutations } from "./mutations";


export const StoreActions = {
    getLessonPlans: 'getLessonPlans',
    clearState: 'clearState'
};

export const actions = {
    [StoreActions.getLessonPlans] ({ commit }) {
        commit(StoreMutations.SET_LOADING, true);

        LessonPlanRepository.getAll().then( res => {
            let lessonPlans = res.lessonPlans.map(it => {
                return new LessonPlan(it);
            })
            commit(StoreMutations.SET_LESSON_PLANS, lessonPlans);
        }).catch(err => {
            commit(StoreMutations.SET_ERROR, err);
        }).finally(() => {
            commit(StoreMutations.SET_LOADING, false);
        });
    },
    [StoreActions.clearState] ({commit}) {
        commit(StoreMutations.SET_ERROR, null);
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_LESSON_PLANS, []);
    }
}