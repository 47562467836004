export default {
    words: {
        path: '/words',
        name: 'words'
    },
    home: {
        path: '/home',
        name: 'home'
    },
    landing: {
        path: '/:slug',
        name: 'landing'
    },
    wordForm: {
        path: '/words/:word',
        name: 'wordform'
    },
    login: {
        path: '/login',
        name: 'login'
    },
    studentLogin: {
        path: '/login/student',
        name: 'studentLogin'
    },
    studentLoginWithCode: {
        path: '/login/student/:accessCode',
        name: 'studentLoginWithCode'
    },
    register: {
        path: '/register',
        name: 'register'
    },
    forgotPassword: {
        path: '/forgotPassword',
        name: 'ForgotPassword'
    },
    resetPassword: {
        path: '/resetPassword/:token',
        name: 'ResetPassword'
    },
    completeProfile: {
        path: '/profile/setup',
        name: 'completeProfile'
    },
    myProfile: {
        path: '/profile',
        name: 'myProfile'
    },
    myStudentProfile: {
        path: '/profile/student',
        name: 'myStudentProfile'
    },
    bulkAddProfiles: {
        path: '/profile/add/bulk',
        name: 'bulkAddProfiles'
    },
    selectProfile: {
        path: '/profile/select',
        name: 'selectProfile'
    },
    admin: {
        home: {
            path: '/admin',
            name: 'adminHome',
            title: 'Home'
        },
        accessCodes: {
            path: '/admin/accessCodes',
            name: 'accessCodes',
            title: 'Access Codes'
        },
        doctors: {
            path: '/admin/doctors',
            name: 'adminDoctors',
            title: 'Doctors'
        },
        schools: {
            path: '/admin/schools',
            name: 'adminSchools',
            title: 'Schools'
        },
        words: {
            path: '/admin/words',
            name: 'adminWords',
            title: 'Words'
        },
        questions: {
            path: '/admin/words/:wordId/questions',
            name: 'adminQuestions'
        },
        questionEdit: {
            path: '/admin/question/:questionId/edit',
            name: 'adminQuestionEdit'
        },
        questionCreate: {
            path: '/admin/words/:wordId/questions/create',
            name: 'adminQuestionCreate'
        },
        homepages: {
            path: '/admin/homepages',
            name: 'adminHomepages',
            title: 'Homepages'
        },
        homepagesCreate: {
            path: '/admin/homepages/create',
            name: 'adminHomepagesCreate',
            title: 'Create Homepage'
        },
        homepagesEdit: {
            path: '/admin/homepages/:id/edit',
            name: 'adminHomepagesEdit',
            title: 'Edit Homepage'
        },
        users: {
            path: '/admin/users',
            name: 'adminUsers',
            title: 'Users'
        },
    },
    lancasterCanlanding: {
        path: '/lancasterlebanon-can',
        name: 'LancasterCanLanding'
    },
    lancLebLogin: {
        path: '/login/lancasterlebanon',
        name: 'LancLebLogin'
    },
    ohioCanlanding: {
        path: '/ohio-can',
        name: 'OhioCanLanding'
    },
    pediatricianCanLanding: {
        path: '/pediatrician-can',
        name: 'PediatricianCanLanding'
    },
    schoolCanLanding: {
        path: '/school-can',
        name: 'SchoolCanLanding'
    },
    businessCanLanding: {
        path: '/business-can',
        name: 'BusinessCanLanding'
    },
    healthBooks: {
        path: '/can-catalog',
        name: 'CanCatalog'
    },
    lessonPlans: {
        path: '/lesson-plans',
        name: 'LessonPlans'
    },
    foodAllergyResources: {
        path: '/food-allergy',
        name: 'FoodAllergy'
    },
    readHealthbook: {
        path: '/can-catalog/:bookId/read',
        name: 'CanCatalogRead'
    },
    verifyEmail: {
        path: '/profile/email/verify',
        name: 'VerifyEmail'
    },
    passport: {
        path: '/passport',
        name: 'Passport'
    },
    canboard: {
        path: '/canboard/:wordId',
        name: 'Canboard'
    },
    selectCanboard: {
        path: '/canboards',
        name: 'SelectCanboard'
    },
    root: {
        path: '/'
    },
    notFound: {
        path: '/notfound',
        name: 'NotFound'
    },
    catchAll: {
        path: "/:catchAll(.*)",
        name: 'NotFound'
    },

    adminLinks() {
        return [
            this.admin.home,
            this.admin.accessCodes,
            this.admin.homepages,
            // this.admin.doctors,
            // this.admin.schools,
            this.admin.users,
            this.admin.words
        ];
    }
}