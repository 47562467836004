<template>
  <div>
    <main-nav />
    <div class="container-full primary">
      <div class="row mt20">
        <div class="col-sm-12">
          <h2>Our Class Can!</h2>
        </div>
        <div
          class="col-xs-12 col-sm-8 col-md-4 col-sm-offset-2 col-md-offset-4"
        >
          <img :src="canLogo" width="100" class="mt20 ml10" />
          <br /><br />
          <sync-loader
            :loading="loading"
            :color="`#f35b2c`"
            :size="'15px'"
            style="margin-top: 20px"
          ></sync-loader>

          <div class="row mt20" v-if="error">
            <div class="col-sm-12">
              <div class="alert" role="alert">
                {{ error }}
              </div>
            </div>
          </div>

          <div v-if="profiles.length == 0">
            <div class="form-group">
              <label
                v-tooltip="'You should have received this from your teacher'"
              >
                Classroom Code
                <i class="fa fa-info-circle" />
              </label>
              <input
                class="form-control"
                placeholder="smith"
                :disabled="loading"
                v-model="accessCode"
                v-on:keyup.enter="nextClick"
              />
              <br>
              <button
                :disabled="loading"
                @click="nextClick"
                class="btn btn-primary btn-login"
              >
                Next
              </button>
              <br><br>
              <!-- <p>Or</p>
              <button v-if="schools.length == 0" class="btn btn-default" style="width:100%;" @click="selectSchoolClick">Select Your School District</button>

              <select-school-classroom
                :schools="schools"
                @selectedSchool="clickedSchool"
                @selectedClassroom="clickedClassroom"
              /> -->
            </div>
          </div>
        </div>
      </div>


      <div class="row" v-if="profiles.length > 0 && !selectedProfile">
        <div class="col-sm-12">

          <h4>
            Select Your Name</h4>


        <div class="col-sm-6 col-md-4 col-lg-3" v-for="profile in profiles" :key="profile.id">
          <div class="ml10 mr10 primary curved card" @click="clickedProfile(profile)">
            <p class="card-name">{{profile.firstName}} {{profile.lastInitial}}</p>
          </div>
        </div>
        </div>
        <div class="col-sm-12">
          <button
            :disabled="loading"
            @click="cancelSelectingName"
            class="btn btn-default mt10"
          >
            Back
          </button>
        </div>
      </div>


      <div class="row mt20" v-if="selectedProfile && selectedProfile.requiresPasscode">

        <div class="col-sm-4 col-sm-offset-4">
          <div class="primary curved card">
            <p class="card-name">{{selectedProfile.firstName}} {{selectedProfile.lastInitial}}</p>
          </div>
        </div>

        <div class="col-xs-12 col-sm-8 col-md-4 col-sm-offset-2 col-md-offset-4">
          <div class="form-group">
            <label>Password</label>
            <input class="form-control" v-model="passcode" placeholder="123456"
                v-on:keyup.enter="loginClick" />
          </div>

            <button
              :disabled="loading"
              @click="loginClick"
              class="btn btn-primary btn-login"
            >
              Login
            </button>

            <button
              :disabled="loading"
              @click="selectedProfile = null"
              class="btn btn-default btn-login mt10"
            >
              Back
            </button>
        </div>
      </div>


      <a href="https://api.app.thelandofcan.com/public/privacy.html" target="_blank" style="margin-top: 20px; color: #f35b2c;">Privacy Policy</a>
    </div>
  </div>
</template>

<script>
import routes from "@/routes";
import StorePaths from "@/store/StorePaths";
import { mapState } from "vuex";
import { StoreState as state } from "@/store/login/state";
import { StoreActions as actions } from "@/store/login/actions";
import MainNav from "@/components/MainNav.vue";
import CanLogo from "@/assets/images/canlogo.png";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import { StoreMutations as mutations } from '@/store/login/mutations';
// import SelectSchoolClassroom from './SelectSchoolClassroom.vue';
export default {
  name: "UserLogin",
  components: {
    MainNav,
    SyncLoader,
    // SelectSchoolClassroom,
  },
  mounted() {
    if (this.$hasSession()) {
      this.$router.push({ name: routes.words.name });
      return;
    }

    let accessCode = this.$route.params.accessCode;
    if (accessCode) {
      this.accessCode = accessCode;
      this.nextClick();
    }
  },
  beforeUnmount() {
    this.dispatch(actions.clearState);
  },
  data() {
    return {
      accessCode: "",
      passcode: "",
      canLogo: CanLogo,
      selectedProfile: null,
      selectedSchool: null,
      selectedClassroom: null
    };
  },
  computed: {
    ...mapState(StorePaths.login, [state.loading, state.error, state.profiles, state.schools, state.profileOwner]),
  },
  methods: {
    commit(key, val) {
      this.$store.commit(`${StorePaths.login}/${key}`, val);
    },
    dispatch(key, val) {
      this.$store.dispatch(`${StorePaths.login}/${key}`, val);
    },
    loginClick() {
      this.commit(mutations.SET_ERROR, null);
      this.dispatch(actions.studentLogin, {
        accessCode: this.accessCode,
        profileId: this.selectedProfile.id,
        passcode: this.passcode,
      });
    },
    nextClick() {
      this.commit(mutations.SET_ERROR, null);
      this.dispatch(actions.getProfiles, this.accessCode)
    },
    cancelSelectingName() {
      this.commit(mutations.SET_ERROR, null);
      this.commit(mutations.SET_PROFILES, []);
      this.accessCode = "";
    },
    clickedProfile(profile) {
      this.commit(mutations.SET_ERROR, null);
      this.selectedProfile = profile;

      if(!profile.requiresPasscode) {
        this.loginClick()
      }
    },
    selectSchoolClick() {
      this.dispatch(actions.getSchools);
    },
    clickedSchool(school) {
      this.selectedSchool = school;
      if(school.classrooms.length == 0) {
        this.dispatch(actions.getProfilesBySchoolClassroom, {
          schoolId: this.selectedSchool.id
        });
      }
    },
    clickedClassroom(classroom) {
      this.selectedClassroom = classroom;
      this.dispatch(actions.getProfilesBySchoolClassroom, {
        schoolId: this.selectedSchool.id,
        classroomId: this.selectedClassroom.id
      });
    }
  },
};
</script>

<style scoped>
.form-group {
  background: #efefef;
  border-radius: 15px;
  padding: 10px;
}

input {
  border-radius: 10px !important;
}

label {
  float: left;
  margin-left: 10px;
  color: #f35b2c;
}

.btn-login {
  border-radius: 10px;
  font-size: medium;
  width: 100%;
}

.btn-register {
  border-radius: 10px;
  font-size: medium;
  width: 100%;
  margin-top: 20px;
}

.btn-forgot {
  margin-top: 20px;
}

.logo {
  height: 50px;
  margin-top: 10px;
}

.container-full {
  padding: 0px;
}

.alert {
  background: #f35b2c;
  color: white !important;
  border-radius: 15px;
  font-size: medium;
  font-weight: bold;
}

.curved {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  cursor: pointer;
  height:85px;
  line-height:85px;
  font-weight: bold;
  font-size: x-large;
  overflow: scroll;
  background: #efefef;
}

.card-name {
  padding-left:10px;
  padding-right: 10px;
}

.loc-bg {
  background: #f35b2c !important;
}

.white {
  color:white;
}
</style>