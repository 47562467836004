export default class LessonPlan {
    constructor(adapter = {
        id: 0,
        name: "",
        link: "",
        image: ""
    }) {
        this.link = adapter.link;
        this.image = adapter.image;
        this.name = adapter.name;
        this.id = adapter.id;
    }
}