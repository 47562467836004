<template>
  <Navbar :class="navbarClass">
    <template #brand>
      <a  :class="navbarItemClass" class="navbar-brand" href="/home"
        >The Land of Can</a
      >
    </template>
    <template #collapse>
      <NavbarNav right>
        <NavbarText>
          <router-link class="nav-item" :class="navbarItemClass" :to="homeRoute"> Home </router-link>
        </NavbarText>
        <NavbarText v-if="!loggedIn">
          <router-link class="nav-item" :class="navbarItemClass" :to="loginRoute"> Login </router-link>
        </NavbarText>
        <!-- <NavbarText v-if="!loggedIn">
          <router-link class="nav-item" :class="navbarItemClass" :to="registerRoute"> Register </router-link>
        </NavbarText> -->
        <NavbarText v-if="!loggedIn">
          <router-link class="nav-item" :class="navbarItemClass" :to="studentLoginRoute"> Lancaster/Lebanon CAN </router-link>
        </NavbarText>
        <NavbarText v-if="!loggedIn">
          <router-link class="nav-item" :class="navbarItemClass" :to="ohioCanRoute"> Ohio CAN</router-link>
        </NavbarText>
        <NavbarText v-if="!loggedIn">
          <router-link class="nav-item" :class="navbarItemClass" :to="pediatricianCanRoute"> Pediatrician CAN</router-link>
        </NavbarText>
        <NavbarText v-if="!loggedIn">
          <router-link class="nav-item" :class="navbarItemClass" :to="schoolCanRoute"> School CAN</router-link>
        </NavbarText>
        <NavbarText v-if="!loggedIn">
          <router-link class="nav-item" :class="navbarItemClass" :to="businessCanRoute"> Business CAN</router-link>
        </NavbarText>
        <NavbarText v-if="canLinks">
          <router-link class="nav-item" :class="navbarItemClass" :to="canboardRoute">
            CAN Board
          </router-link>
        </NavbarText>
        <NavbarText  v-if="canLinks">
          <router-link class="nav-item" :class="navbarItemClass" :to="passportRoute">
            CAN Passport
          </router-link>
        </NavbarText>
        <NavbarText v-if="switchProfile">
          <router-link class="nav-item" :class="navbarItemClass" :to="switchProfilesRoute">
            Switch Profile
          </router-link>
        </NavbarText>
        <NavbarText v-if="loggedIn">
          <router-link class="nav-item" :class="navbarItemClass" :to="profileRoute">
            My Students
          </router-link>
        </NavbarText>
        <NavbarText @click="$logout()" v-if="loggedIn">
          <a class="nav-item" :class="navbarItemClass" href="#">Logout</a>
        </NavbarText>
      </NavbarNav>
      <NavbarNav right v-if="admin">
        <NavbarText>
          <router-link class="nav-item" :class="navbarItemClass" :to="adminRoute"> <strong>Admin</strong> </router-link>
        </NavbarText>
      </NavbarNav>
    </template>
  </Navbar>
</template>

<script>
import routes from "@/routes";
import { StoreActions as GlobalActions } from "@/store/global/actions";

export default {
  name: "MainNav",
  props: {
    canLinks: {
        default: false
    },
    switchProfile: {
      default: false
    },
    color: {
      default: 'orange'
    }
  },
  mounted() {
    this.$store.dispatch(GlobalActions.getSessionDetails, (session) => {
      this.userProfile = !session.studentProfile;
    });

    this.$store.dispatch(GlobalActions.getUser, (user) => {
      this.admin = user.admin;
    });

    this.loggedIn = this.$hasSession();
  },
  data() {
    return {
      userProfile: true,
      loggedIn: false,
      admin: false
    };
  },
  computed: {
    navbarClass() {
      return {
        'navbar-can-orange': this.color == 'orange',
        'navbar-can-white': this.color == 'white',
        'navbar-can-black': this.color == 'black',
        'navbar-can': true
      }
    },
    navbarItemClass() {
      return {
        'nav-text-white': this.color == 'orange' || this.color == 'black',
        'nav-text-orange': this.color == 'white'
      }
    },
    homeRoute() {
      return {
        name: routes.home.name
      }
    },
    profileRoute() {
      if (this.userProfile) {
        return {
          name: routes.myProfile.name,
        };
      } else {
        return {
          name: routes.myStudentProfile.name,
        };
      }
    },

    passportRoute() {
      return {
        name: routes.passport.name,
      };
    },
    canboardRoute() {
      return {
        name: routes.selectCanboard.name,
      };
    },
    switchProfilesRoute() {
      return {
        name: routes.selectProfile.name
      }
    },
    loginRoute() {
      return {
        name: routes.login.name
      }
    },
    registerRoute() {
      return {
        name: routes.register.name
      }
    },
    studentLoginRoute() {
      return {
        name: routes.lancLebLogin.name
      }
    },
    adminRoute() {
      return {
        name: routes.admin.home.name
      }
    },
    ohioCanRoute() {
      return {
        name: routes.ohioCanlanding.name
      }
    },
    pediatricianCanRoute() {
      return {
        name: routes.pediatricianCanLanding.name
      }
    },
    schoolCanRoute() {
      return {
        name: routes.schoolCanLanding.name
      }
    },
    businessCanRoute() {
      return {
        name: routes.businessCanLanding.name
      }
    }
  },
};
</script>

<style scoped>
.navbar-can {
  margin: 20px;
  padding: 0px;
  border-radius: 10px;

}
.navbar-can-orange {
  background: #f35b2b;
}

.navbar-can-black {
  background: #000;
}

.nav-text-white {
  color: white;
}

.nav-text-orange { 
  color: #f35b2b;
}
</style>