
import MinifiedUser from "@/adapters/MinifiedUser";
import AdminUserRepository from "@/API/AdminUserRepository";
import { StoreMutations } from "./mutations";

export const StoreActions = {
    getUsers: 'getUsers',
    clearState: 'clearState',
};

export const actions = {
    [StoreActions.getUsers] ({ commit }) {
        AdminUserRepository.list().then((data) => {
            let users = data.users.map(it => {
                return new MinifiedUser(it);
            })
            commit(StoreMutations.SET_USERS, users);
        }).catch( err => {
            commit(StoreMutations.SET_ERROR, err);
        })
    },
    [StoreActions.clearState] ({ commit }) {
        commit(StoreMutations.SET_LOADING, false);
        commit(StoreMutations.SET_ERROR, null);
    }
}