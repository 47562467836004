
export default class MinifiedUser {
    constructor(adapter = {
        firstName: "",
        lastName: "",
        email: "",
        createdAt: "",
        homepageLink: null
    }) {
        this.firstName = adapter.firstName;
        this.lastName = adapter.lastName;
        this.email = adapter.email;
        this.createdAt = adapter.createdAt;
        this.homepageLink = adapter.homepageLink;
    }
}